/* eslint-disable react/jsx-pascal-case */
import Product_Form from "./Product_Form";





// @ 新增 _ 商品
const Create_Product = () => {



    return <>
    
              <Product_Form />

           </>

} ;


export default Create_Product
       