/* eslint-disable react/jsx-pascal-case */
import Admin_Data_Render from "templates/layout/Admin_Data_Render" ; 



/* @ 洗美頁面 ( 洗美資料、方案資料 ) */
const Services = () => {
 
    
    return  <Admin_Data_Render data_Type = { "service" } />

            
} ;

export default Services ;
