/* eslint-disable react/jsx-pascal-case */

import { useDispatch } from "react-redux";
import { string_Short } from "utils/string/edit_string";
import Update_Service from "components/services/edit/Update_Service";
import { set_Side_Panel } from "store/actions/action_Global_Layout";
import { click_Show_Edit_Customer } from "store/actions/action_Customer";
import { usePet_Update_Panel } from "hooks/data/usePet" ;
import { Service_Type } from "utils/Interface_Type"




// # 服務列 : 內容
const Service_Rows_Content = ( { data } : { data : any } ) => {


    const dispatch = useDispatch() ;


    const customer       = data['customer'] ;
    const pet            = data['pet'] ;


    // 基礎、洗澡、美容 [ basic、bath、beauty ]
    const service_Type   = data['service_type'] ;
    const q_Code         = data['q_code'] ;

    // 客戶 [ customer ]
    const cus_Name       = customer ? customer['name'] : '' ;

    // 寵物 [ pet ]
    const pet_Name       = pet ? pet['name'] : '' ;
    const pet_Species    = pet ? pet['species'] : '' ;


    let style            = '' ;
    if( service_Type === '基礎' ) style = 'is-warning' ;
    if( service_Type === '洗澡' ) style = 'is-success' ;
    if( service_Type === '美容' ) style = 'is-danger' ;



    let tagStyle = 'tag '+style+' is-medium is-light is-rounded pointer f_11 relative m_Right_5' ;


    // 點選 _ Qcode
    const click_Qcode    = () => dispatch( set_Side_Panel( true , <Update_Service /> , { service_Type : service_Type , preLoadData : data  } as { service_Type : Service_Type  } ) ) ;

    // 點選 _ 客戶
    const click_Customer = ( cus_Id : string ) => dispatch( click_Show_Edit_Customer( cus_Id , customer ) ) ;
      
    // 點選 _ 寵物
    const click_Pet      = usePet_Update_Panel()
    



  return <div className = "relative" style = {{ left : "37px" }} >
                  
            <span className={ tagStyle } onClick={ click_Qcode } > Q{ q_Code }
            
                { /* 預約標示 */ }
                <b className="absolute f_9" style={{ top:"-6px" , left:"0px" }}> 
                    { data['service_status'] === '已到店' ? '' : '預' }  
                </b>
            
            </span> 

            <span className="relative tag is-medium pointer f_11 m_Right_5" onClick={ () => click_Pet( pet ) } >
                
                { string_Short( pet_Name ) } ( { string_Short( pet_Species ) } )

            </span>

            <span className="relative tag is-medium pointer f_11"  onClick={ () => click_Customer( customer.id ) } >

                { string_Short( cus_Name ) }

            </span>

        </div>



} ;

export default Service_Rows_Content  