/* eslint-disable react/jsx-pascal-case */


import Admin_Data_Render from "templates/layout/Admin_Data_Render" ; 


/* @ 安親頁面 */
const Care = ( ) => {


   return  <Admin_Data_Render data_Type = { "care" }  />

} ;


export default Care