import React from "react" ;


/* @ 整體、全局 _ 參數 / 設定 */
interface ISetting {


}

const initState = {

                   
} ;

const reducer_Global_Setting = ( state : ISetting = initState , action : any ) => {

    switch( action.type ){

       
         default : return state ;

    }


} ;

export default reducer_Global_Setting ;

