/* eslint-disable react/jsx-pascal-case */
import Service_Sign from "components/index/components/Service_Sign" ;
import Service_Rows_Content from "./components/Service_Rows_Content" ;
import Shop_Status_Arrow from "./components/sub_components/Shop_Status_Arrow" ;




// # 服務列
const Service_Rows = ( { data } : { data : any } ) => {
    

    return <div className = "title is-6 relative m_Bottom_20" >

               { /* 服務相關標示 ( 異常、銷單、是否付費 )  */ }
               <Service_Sign is_error = { data['is_error'] } is_delete = { data['is_delete'] } amount_paid = { data['amount_paid'] } amount_payable = { data['amount_payable'] }   />

               { /* 向左 _ 調整到店狀態 */ }   
               <Shop_Status_Arrow direction = "left" data = { data } />

               { /* 服務列：內容 */ }
               <Service_Rows_Content data = { data } />
           
               { /* 向右 _ 調整到店狀態 */ }   
               <Shop_Status_Arrow direction = "right" data = { data } />
 
           </div> ;

} ;

export default Service_Rows ;