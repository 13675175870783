


/* @ 服務異常  */
interface IError {

    

}

const initState = {

  

} ;


const reducer_Error = ( state : IError = initState , action : any ) => {

    switch( action.type ){


     
       

        default : return state ;

    }

} ;

export default reducer_Error ;
