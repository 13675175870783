/* eslint-disable react/jsx-pascal-case */

import Admin_Data_Render from "templates/layout/Admin_Data_Render" ; 



/* @ 寵物頁面 */
const Pets = () => {

  
  return <Admin_Data_Render data_Type = { "pet" } />

} ;

export default Pets ;
