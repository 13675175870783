/* eslint-disable react/jsx-pascal-case */
import Admin_Data_Render from "templates/layout/Admin_Data_Render" ; 


/* @ 客戶頁面  */
const Customers = () => {


   return <Admin_Data_Render data_Type = { "customer" } />
                              
};

export default Customers ;


