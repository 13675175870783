import React from "react" ;


/* @ 管理區  */
interface IManagement {



}

const initState = {



} ;


const reducer_Management = ( state : IManagement = initState , action : any ) => {


    switch( action.type ){



        default : return state ;

    }

} ;

export default reducer_Management ;
